/**
 * @file Anything that can run on every page.
 */

/**
 * Global functions.
 *
 * Runs component js and any global tasks.
 */
const vGlobal = () => {
	import('../components/alert-bar/alert-bar').then(({ default: vAlertBar }) => { vAlertBar(); });
	import('../components/header/header').then(({ default: vHeader }) => { vHeader(); });
	import('../components/nav-dropdown/nav-dropdown').then(({ default: vNavDropdown }) => { vNavDropdown(); });
	import('../components/sticky-header/sticky-header').then(({ default: vStickyHeader }) => { vStickyHeader(); });
	import('../components/mobile-nav/mobile-nav').then(({ default: vMobileNav }) => { vMobileNav(); });
	import('../components/footer/footer').then(({ default: vFooter }) => { vFooter(); });

	import('../components/stats/stats').then(({ default: vStats }) => { vStats(); });
	import('../components/vertical-tabs/vertical-tabs').then(({ default: vVerticalTabs }) => { vVerticalTabs(); });
	import('../components/horizontal-tabs/horizontal-tabs').then(({ default: vHorizontalTabs }) => { vHorizontalTabs(); });
	import('../components/dropdown-tabs/dropdown-tabs').then(({ default: vDropdownTabs }) => { vDropdownTabs(); });

	import('../components/testimonials/testimonials').then(({ default: vTestimonials }) => { vTestimonials(); });
	import('../components/video-trigger/video-trigger').then(({ default: vVideoTrigger }) => { vVideoTrigger(); });
	if (document.querySelector('.v-post-social')) import('../components/post-social/post-social').then(({ default: vPostSocial }) => { vPostSocial(); });

	if (document.querySelectorAll('.v-accordions-list').length) import('../components/accordions-list/accordions-list').then(({ default: vAccordionsList }) => { vAccordionsList(); });

	import('../components/cookie-banner/cookie-banner').then(({ default: vCookieBanner }) => { vCookieBanner(); });

	import('../components/facet-filters/facet-filters').then(({ default: vFacetFilters }) => { vFacetFilters(); });
	import('../components/pagination/pagination').then(({ default: vPagination }) => { vPagination(); });
	import('../components/testimonial-list/testimonial-list').then(({ default: vTestimonialList }) => { vTestimonialList(); });

	const scrollbarWidth = window.innerWidth - document.body.clientWidth;
	document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);

	const selects = document.querySelectorAll('select');
	[...selects].forEach((select) => {
		const selectParent = select.parentNode;
		if (selectParent.classList.contains('v-form__select')) {
			return;
		}

		const wrapper = document.createElement('span');
		const arrow   = document.createElement('span');
		wrapper.classList.add('v-form__select');
		arrow.classList.add('v-form__select-arrow');
		selectParent.insertBefore(wrapper, select);
		wrapper.appendChild(select);
		wrapper.appendChild(arrow);
		select.classList.add('v-form__select-field');
	});

	const submitFields = document.querySelectorAll('.v-form__field--submit');
	[...submitFields].forEach((field) => {
		const form = field.closest('.wpcf7-form');
		const responseBox = form.querySelector('.wpcf7-response-output');
		field.appendChild(responseBox);
	});

	const lazyBgsObserver = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				if (entry.target.classList.contains('v-lazy-bg--loaded')) {
					return;
				}

				const lazyBg = entry.target;
				lazyBg.classList.add('v-lazy-bg--loaded');
				lazyBgsObserver.unobserve(lazyBg);
			}
		});
	});
	const lazyBgs = document.querySelectorAll('.v-lazy-bg');
	[...lazyBgs].forEach((lazyBg) => {
		lazyBgsObserver.observe(lazyBg);
	});

	/**
	 * Global Window Load.
	 *
	 * Runs polyfills on page load.
	 */
	async function onWindowLoad() {
		const body = document.querySelector('body');
		if (!body.classList.contains('admin-bar')) {
			const { listen } = await import(/* webpackExports: "listen" */ 'quicklink');

			listen({
				ignores: [
					(url) => {
						const cleanUrl = url.replace(/#.*/, '');
						return cleanUrl === window.location.href;
					},
					/.*\/wp-admin\/.*/,
				],
			});
		}
	}

	if (document.readyState === 'complete') {
		onWindowLoad();
	} else {
		window.addEventListener('load', onWindowLoad);
	}
};

if (document.readyState !== 'loading') {
	vGlobal();
} else {
	document.addEventListener('DOMContentLoaded', vGlobal);
}
